<template>
  <div class="m-feedback-edit">
    <b-notification
      v-if="savingError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('feedback.savingError') }}
      <div class="b-be-errors" v-if="errorsFromBe.length > 0">
        <hr />
        <ul>
          <li :key="key" v-for="(error, key) in errorsFromBe">
            {{ error }}
          </li>
        </ul>
      </div>
    </b-notification>
    <b-loading :active.sync="loading"></b-loading>
    <h1 class="title is-1">{{ heading }}</h1>
    <div class="columns">
      <div class="column is-one-third">
        <div class="container">
          <b-field :label="$t('feedback.from')" :type="fromType" :message="fromValidationMessage">
            <b-select v-model="fromUser" :loading="loadingUsers" @change="resetValidation">
              <option v-for="user in users" :value="user._id" :key="user._id">
                {{ user.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="column is-one-third">
        <b-field :label="$t('feedback.to')" :type="toType" :message="toValidationMessage">
          <b-select v-model="toUser" :loading="loadingUsers" @change="resetValidation">
            <option v-for="user in users" :value="user._id" :key="user._id">
              {{ user.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field :label="$t('feedback.authorVisibility')">
          <b-checkbox v-model="anonymous">
            {{ $t('feedback.anonymous') }}
          </b-checkbox>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <div class="container">
          <b-field :label="$t('feedback.type')" :type="typeType" :message="typeValidationMessage">
            <b-select v-model="type" @change="resetValidation">
              <option v-for="(name, value) in TYPES" :value="value" :key="value">
                {{ name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="column is-one-third">
        <b-field :label="$t('feedback.value')" :type="valueType" :message="valueValidationMessage">
          <b-select v-model="value" :loading="loadingUsers" @change="resetValidation">
            <option v-for="(name, value) in feedbackValues" :value="value" :key="value">
              {{ name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-one-third">
        <b-field :label="$t('feedback.created')" :type="createdType" :message="createdValidationMessage">
          <b-datetimepicker v-model="created" />
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field :label="$t('feedback.comment')">
          <b-input
            type="textarea"
            v-model="comment"
            :placeholder="$t('feedback.commentPlaceholder')"
            :maxlength="limits.comment"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field is-grouped">
          <div class="control">
            <b-button @click="cancel" type="is-secondary">{{ $t('generic.cancelButton') }}</b-button>
          </div>
          <div class="control">
            <b-button :disabled="loading" @click="dispatch" type="is-primary" :loading="saving"
              >{{ $t('generic.saveButton') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as namespaces from '../../../store/namespaces';
import * as actions from '../../../store/actions';
import clientFeedback from '../../../api/feedback';
import logger from '../../../utils/logger';
import clientUsers from '../../../api/users';

export default {
  name: 'FeedbackEdit',
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      availableTraits: [3, 11, 10, 7, 12, 1, 8, 2, 5],
      TYPES: Object.freeze({
        1: this.$t('feedback.types[0].title')
      }),
      users: [],
      loadingUsers: false,
      limits: {
        comment: 10000
      },
      loading: false,
      loadingError: false,
      saving: false,
      savingError: false,
      errorsFromBe: [],
      fromUser: '',
      toUser: '',
      type: '',
      value: '',
      comment: '',
      targetSeen: false,
      created: new Date(),
      anonymous: false,
      fromValidationMessage: '',
      fromType: '',
      toValidationMessage: '',
      toType: '',
      typeValidationMessage: '',
      typeType: '',
      valueValidationMessage: '',
      valueType: '',
      createdValidationMessage: '',
      createdType: ''
    };
  },
  computed: {
    feedbackValues() {
      let values = {};
      this.availableTraits.forEach(traitNum => {
        values[traitNum] = this.$t('feedback.thanks[' + (traitNum - 1) + '].title');
      });
      return values;
    },
    rolesRegistry() {
      if (this.$store.state.system.registries) {
        return this.$store.state.system.registries.roles;
      }
      return [];
    },
    isEdit() {
      return !!this.id;
    },
    heading() {
      if (this.isEdit) {
        return this.$t('feedback.editFeedbackHeading');
      } else {
        return this.$t('feedback.createFeedbackHeading');
      }
    }
  },
  methods: {
    createDataPayload() {
      const data = {
        fromUser: this.fromUser || null,
        toUser: this.toUser || null,
        type: parseInt(this.type),
        value: parseInt(this.value),
        targetSeen: this.targetSeen,
        anonymous: !!this.anonymous,
        comment: this.comment,
        createdAt: this.created
      };
      if (this.isEdit) {
        return { id: this.id, data };
      } else {
        return data;
      }
    },
    loadFeedback() {
      if (!this.id) {
        return false;
      }
      this.loading = true;
      this.loadingError = false;
      clientFeedback
        .get(this.id)
        .then(feedback => {
          this.fromUser = feedback.fromUser ? feedback.fromUser._id : '';
          this.toUser = feedback.toUser ? feedback.toUser._id : '';
          this.type = feedback.type;
          this.value = feedback.value;
          this.anonymous = feedback.anonymous;
          this.comment = feedback.comment;
          this.targetSeen = feedback.targetSeen;
          this.created = new Date(feedback.createdAt);
        })
        .catch(error => {
          this.loadingError = true;
          logger.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // TODO: refactor, same method as in other modules (teams, feedback etc.)
    loadUsers() {
      this.loadingUsers = true;
      return clientUsers
        .list()
        .then(users => {
          let usersForSelect = [];
          usersForSelect.push({
            _id: '',
            name: this.$t('generic.none')
          });

          users.forEach(user => {
            let company = user.company ? ' (' + user.company.name + ')' : '';
            usersForSelect.push({
              _id: user._id,
              name: user.nameLast + ' ' + user.nameFirst + company
            });
          });
          this.users = usersForSelect;
        })
        .finally(() => {
          this.loadingUsers = false;
        });
    },
    isFormValid() {
      let status = true;
      if (this.fromUser === '') {
        (this.fromType = 'is-danger'), (this.fromValidationMessage = this.$t('feedback.emptyAuthorError'));
        status = false;
      }
      if (this.toUser === '') {
        (this.toType = 'is-danger'), (this.toValidationMessage = this.$t('feedback.emptyTargetError'));
        status = false;
      }
      if (this.fromUser === this.toUser) {
        (this.fromType = 'is-danger'), (this.fromValidationMessage = this.$t('feedback.sameAuthorError'));
        (this.toType = 'is-danger'), (this.toValidationMessage = this.$t('feedback.sameAuthorError'));
        status = false;
      }
      if (this.type === '') {
        (this.typeType = 'is-danger'), (this.typeValidationMessage = this.$t('feedback.emptyTypeError'));
        status = false;
      }
      if (this.value === '') {
        (this.valueType = 'is-danger'), (this.valueValidationMessage = this.$t('feedback.emptyValueError'));
        status = false;
      }
      if (!this.created) {
        (this.valueType = 'is-danger'), (this.createdValidationMessage = this.$t('feedback.emptyCreatedError'));
        status = false;
      }
      return status;
    },
    resetValidation() {
      this.fromType = '';
      this.fromValidationMessage = '';
      this.toType = '';
      this.toValidationMessage = '';
      this.typeType = '';
      this.typeValidationMessage = '';
      this.valueType = '';
      this.valueValidationMessage = '';
      this.createdType = '';
      this.createdValidationMessage = '';
    },
    dispatch() {
      if (!this.isFormValid()) {
        return false;
      }
      this.saving = true;
      this.savingError = false;
      this.errorsFromBe = [];
      let payload = this.createDataPayload();
      this.$store
        .dispatch(namespaces.FEEDBACK + (this.isEdit ? actions.EDIT_FEEDBACK : actions.ADD_FEEDBACK), payload)
        .then(() => {
          this.$router.replace({ name: 'feedback-list' });
        })
        .catch(error => {
          this.savingError = true;
          this.errorsFromBe = error.response.data.errors;
          logger.error(error);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    cancel() {
      this.name = '';
      this.managed = false;
      this.owner = '';
      this.leader = '';
      this.$router.go(-1);
    }
  },
  mounted() {
    this.loadFeedback();
    this.loadUsers();
  },
  watch: {
    id() {
      this.loadFeedback();
    }
  }
};
</script>
